.donators__list {
  max-width: 800px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.donators__list-item {
  margin: 0.5em;
  padding: 0.5em;
}

.skill__container {
  display: flex;
}

.skill__container h4 {
  margin: auto;
}

.user__avatar {
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 0.5em;
}

.donators__desc {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1em;
}

.donators {
  text-align: center;
}
