.stats__grid {
  display: flex;
  justify-content: space-between;
}

.stat {
  text-align: center;
  width: 200px;
}

.status-indicator {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 50%;
  position: relative;
}

.status-indicator.online,
.online .blink {
  background-color: #0fcc45;
}

.status-indicator.offline,
.offline .blink {
  background-color: #ed4245;
  animation: none;
}

.status-indicator.away,
.away .blink {
  background-color: #e4cb11;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
}

.blink {
  display: block;
  width: 15px;
  height: 15px;

  opacity: 0.7;
  border-radius: 50%;

  animation: blink 1s linear infinite;
}

@keyframes blink {
  100% {
    transform: scale(2, 2);
    opacity: 0;
  }
}
